import {ComponentProps, forwardRef, useEffect, useState} from 'react';
import styled from 'styled-components';

/** hooks **/
import { useAppSelector } from '../../hooks/useStore.hook';

/** components **/
import ReCAPTCHA from 'react-google-recaptcha';

export enum Sizes {
  COMPACT = 'compact',
  INVISIBLE = 'invisible',
  NORMAL = 'normal'
}
interface IReCaptchaWrapper extends ComponentProps<any> {
  className?: string;
  onChange?: (token: string | null) => void;
  onLoad?: () => void;
  size?: Sizes;
}
const ReCaptchaWrapper = forwardRef<ReCAPTCHA, IReCaptchaWrapper>(({ className, onChange, onLoad, size = Sizes.INVISIBLE }, ref) => {
  const { reCAPTCHASiteKey } = useAppSelector(store => store.app);
  return (
    <StyledReCaptchaWrapper>
      <ReCAPTCHA
        ref={ref} className={className} sitekey={reCAPTCHASiteKey} size={size} onChange={onChange}
        asyncScriptOnLoad={onLoad}
      />
      {size === Sizes.INVISIBLE && (
        <div className="disclaimer">
          *This site is protected by reCAPTCHA and the Google{' '}
          <a className="link" href="https://policies.google.com/privacy">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a className="link" href="https://policies.google.com/terms">
            Terms of Service
          </a>{' '}
          apply.
        </div>
      )}
    </StyledReCaptchaWrapper>
  );
});
const StyledReCaptchaWrapper = styled.div`
  .disclaimer {
    color: var(--white);
    .link {
      text-decoration: none;
      color: var(--secondary);
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
export default ReCaptchaWrapper;
