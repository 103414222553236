import {ComponentProps, useMemo, useRef, useState} from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

/** hooks **/
import { useAppSelector } from '../../hooks/useStore.hook';
import classNames from 'classnames';

interface IVideo extends ComponentProps<any> {
  className?: string;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  videoSrc: {
    large?: string;
    medium?: string;
    small?: string;
  };
  videoThumb?: string;
}
function Video({ className, controls = false, loop = false, muted = true, playsInline = true, videoSrc, videoThumb }: IVideo) {
  if (!(videoSrc.large ?? videoSrc.medium ?? videoSrc.small)) throw new Error('one of videoSrc -> large or medium or small required');
  const { width } = useAppSelector(store => store.app);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const memoizedVideoSrc = useMemo(() => {
    if (videoSrc.large && width >= 1080) return videoSrc.large;
    if (videoSrc.medium && width < 1080 && width >= 720) return videoSrc.medium;
    if (videoSrc.small && width < 720) return videoSrc.small;
    return videoSrc.medium ?? videoSrc.large ?? videoSrc.small;
  }, [width]);
  function handleVideoDataLoaded() {
    setTimeout(async () => {
      setVideoLoaded(true);
      if (videoRef?.current) {
        await videoRef.current.play();
        videoRef.current.volume = 0.3;
      }
    }, 1000);
  }
  function handleLoadStart() {
    if (isMobile) handleVideoDataLoaded();
  }
  return (
    <StyledVideo className={className} videoThumb={videoThumb}>
      {!videoLoaded && <div className={classNames('video-thumbnail', {visible: !videoLoaded})}/>}
      <video
        ref={videoRef}
        className={classNames('video', { visible: videoLoaded })}
        controls={controls}
        loop={loop}
        muted={muted}
        playsInline={playsInline}
        poster={videoThumb}
        src={memoizedVideoSrc}
        onLoadedData={handleVideoDataLoaded}
        onLoadStart={handleLoadStart}
      />
    </StyledVideo>
  );
}
const StyledVideo = styled.div<{ videoThumb?: string }>`
  margin: 0 var(--side-margin);
  position: relative;
  .video {
    width: 100%;
    opacity: 0;
    transition: all 1s;
    &.visible {
      opacity: 1;
    }
  }
  .video-thumbnail {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(5px);
    background: url(${({ videoThumb }) => videoThumb}) no-repeat 50% 100% / contain;
    opacity: 0;
    transition: all 0.5s;
    &.visible {
      opacity: 1;
    }
  }
`;
export default Video;
